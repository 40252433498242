import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: "https://enquiry.igcsm.online", // Base URL for the API
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Retrieve the token from localStorage or another secure storage
        const token = localStorage.getItem("token");

        // If a token exists, set it in the Authorization header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle the request error
        return Promise.reject(error);
    }
);

// Export the Axios instance
export default axiosInstance;
