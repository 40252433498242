import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Paper } from "@mui/material";
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from "material-react-table";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create column helper for MRT
const columnHelper = createMRTColumnHelper();

const StudentFeedback = () => {
  const navigate = useNavigate();
  const [studentFeedback, setStudentFeedback] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (!isLoggedIn || !token) {
      navigate("/"); // Redirect to login if not logged in
      return;
    }

    const fetchStudentFeedback = async () => {
      try {
        const response = await axios.get("https://api.igcsm.online/api/studentFeedback");
        setStudentFeedback(response.data);
      } catch (error) {
        console.error("Error fetching StudentFeedback data:", error);
      }
    };

    fetchStudentFeedback();
  }, [navigate]);

  // Define columns
  const columns = [
    columnHelper.accessor("studentId", { header: "Student Id" }),
    columnHelper.accessor("studentName", { header: "Student Name" }),
    columnHelper.accessor("emailAddress", { header: "Email Address" }),
    columnHelper.accessor("phoneNumber", { header: "Phone Number" }),
    columnHelper.accessor("subject", { header: "Subject" }),
    columnHelper.accessor("message", { header: "Message" }),
    columnHelper.accessor("collegeName", { header: "College Name" }),
  ];

  const table = useMaterialReactTable({
    columns,
    data: studentFeedback,
    initialState: {
      density: "compact", // Set default density to "compact"
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="page">
        <div className="page-main">
          <Header />
          <div className="my-3 my-md-5">
            <div className="container">
              <div className="page-header">
                <h1 className="page-title">Student Feedback</h1>
              </div>

              <Paper>
                <MaterialReactTable table={table} />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentFeedback;