import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, } from "react-router-dom";

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to fetch userRole from localStorage
  useEffect(() => {
    const role = localStorage.getItem("userRole");
    setUserRole(role); // Set userRole from localStorage
  }, []);

  const buttonStyle = {
    color: isHovered ? "white" : "#467FCF",
    backgroundColor: isHovered ? "#467FCF" : "transparent",
    borderColor: "#467FCF",
    padding: "5px 10px",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
  };

  const handleLogout = () => {
    sessionStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    navigate("/");
  };

  const isActive = (path) => (location.pathname === path ? "nav-link active" : "nav-link");

  return (
    <>
      <div className="header py-4">
        <div className="container">
          <div className="d-flex">
            <Link className="header-brand" to={"/admissions"}>
              <m>ITI AND POLYTECHNIC</m>
            </Link>
            <div className="d-flex order-lg-2 ml-auto">
              <div className="nav-item d-none d-md-flex">
                <button
                  style={buttonStyle}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={handleLogout}
                  className="btn btn-sm btn-outline-primary"
                >
                  LOGOUT
                </button>
              </div>
            </div>
            <a
              href="#"
              className="header-toggler d-lg-none ml-3 ml-lg-0"
              data-toggle="collapse"
              data-target="#headerMenuCollapse"
            >
              <span className="header-toggler-icon" />
            </a>
          </div>
        </div>
      </div>

      <div className="header collapse d-lg-flex p-0" id="headerMenuCollapse">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 ml-auto"></div>
            <div className="col-lg order-lg-first">
              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                <li className="nav-item">
                  <Link to={"/admissions"} className={isActive("/admissions")}>
                    <i className="fa-solid fa-users" />
                    &nbsp; Mobilizer Admissions
                  </Link>
                </li>
                {/* Render 'Users' link only if userRole is 'Admin' */}
                {userRole === "Admin" && (
                  <li className="nav-item">
                    <Link to={"/collegeAdmission"} className={isActive("/collegeAdmission")}>
                      <i className="fa-solid fa-user-plus" />
                      &nbsp; College Admissions
                    </Link>
                  </li>
                )}
                {/* Render 'Users' link only if userRole is 'Admin' */}
                {userRole === "Admin" && (
                  <li className="nav-item">
                    <Link to={"/studentFeedback"} className={isActive("/studentFeedback")}>
                      <i className="fa-solid fa-comments" />
                      &nbsp; Student Feedback
                    </Link>
                  </li>
                )}
                {/* Render 'Users' link only if userRole is 'Admin' */}
                {userRole === "Admin" && (
                  <li className="nav-item">
                    <Link to={"/Enquiries"} className={isActive("/Enquiries")}>
                      <i className="fa-solid fa-envelope" />
                      &nbsp; Enquiries
                    </Link>
                  </li>
                )}
                {/* Render 'Users' link only if userRole is 'Admin' */}
                {userRole === "Admin" && (
                  <li className="nav-item">
                    <Link to={"/users"} className={isActive("/users")}>
                      <i className="fa-solid fa-user-plus" />
                      &nbsp; Users
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;