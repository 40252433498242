import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Box, Button, Paper } from "@mui/material";
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from "material-react-table";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

const columnHelper = createMRTColumnHelper();

const Admission = () => {
  const navigate = useNavigate();
  const [admissions, setAdmissions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (!isLoggedIn || !token) {
      navigate("/");
      return;
    }

    const userRole = localStorage.getItem("userRole");
    setIsAdmin(userRole === "Admin");

    const fetchAdmissions = async () => {
      try {
        const response = await axios.get("https://enquiry.igcsm.online/api/admission", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdmissions(response.data);
      } catch (error) {
        console.error("Error fetching admissions data:", error);
        if (error.response && error.response.status === 401) {
          sessionStorage.clear();
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    };

    fetchAdmissions();
  }, [navigate]);

  const columns = [
    columnHelper.accessor("admissionNo", { header: "Admission No" }),
    columnHelper.accessor("admissionDate", { header: "Admission Date" }),
    columnHelper.accessor("studentName", { header: "Student Name" }),
    columnHelper.accessor("admissionFor", { header: "College Name" }),
    columnHelper.accessor("registeredBy", { header: "Registered By" }),
    // Move Actions column to the end
    {
      id: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <button
              className="btn btn-sm btn-outline-success"
              onClick={() => navigate(`/viewadmission/${row.original._id}`)} 
            >
            View
          </button>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => navigate(`/editadmission/${row.original._id}`)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleDelete(row.original._id)}
          >
            Delete
          </button>
        </Box>
      ),
    },
  ];
  
  const exportToExcel = () => {
    const formattedData = admissions.map((admission) => ({
      Admission_No: admission.admissionNo,
      Admission_Date: admission.admissionDate,
      Branch_Name: admission.branchName,
      Semester_Or_Year: admission.semesterOrYear,
      Student_Name: admission.studentName,
      Fathers_Name: admission.fathersName,
      Mothers_Name: admission.mothersName,
      DOB: admission.dob,
      Gender: admission.gender,
      Category: admission.category,
      Caste: admission.caste,
      Blood_Group: admission.bloodGroup,
      Phone_Number: admission.phoneNumber,
      Email_Address: admission.email,
      Aadhar_Card_No: admission.aadharCard,
      Permanent_Address: admission.address,
      High_School_Board: admission.highSchoolBoard,
      High_School_PassingYear: admission.highSchoolYear,
      High_School_MaxMarks: admission.highSchoolMaxMarks,
      High_School_Percentage: admission.highSchoolPercentage,
      Graduation_Board: admission.graduationBoard,
      Graduation_PassingYear: admission.graduationYear,
      Graduation_MaxMarks: admission.graduationMaxMarks,
      Graduation_Percentage: admission.graduationPercentage,
      College_Name: admission.admissionFor,
      Registered_By: admission.registeredBy,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Admissions");
    XLSX.writeFile(workbook, "AdmissionsData.xlsx");
  };

  const handleDelete = async (admissionId) => {
    if (window.confirm("Are you sure you want to delete this admission?")) {
      const token = localStorage.getItem("token");

      try {
        await axios.delete(`https://enquiry.igcsm.online/api/admission/${admissionId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdmissions(admissions.filter((admission) => admission._id !== admissionId));
        toast.success("Admission deleted successfully!");
      } catch (error) {
        console.error("Error deleting admission:", error);
        toast.error("Error deleting admission!");
      }
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: admissions,
    initialState: {
      density: "compact", // Set default density to "collapsed"
    },
  });

  return (
    <div className="page">
      <div className="page-main">
        <Header />
        <div className="my-3 my-md-5">
          <div className="container">
            <div className="page-header">
              <h1 className="page-title">Mobilizer Admissions</h1>
            </div>
            <Paper>
              <Link
                to="/add-admissions"
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
                className="btn btn-sm btn-outline-primary"
              >
                Add Admission
              </Link>
              {isAdmin && ( // Check if user is Admin before rendering button
                <button
                  variant="contained"
                  color="primary"
                  onClick={exportToExcel}
                  style={{ margin: "10px" }}
                  className="btn btn-sm btn-outline-primary"
                >
                  Export to Excel
                </button>
              )}
              <MaterialReactTable table={table} />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admission;