import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { TextField, Button, Paper, Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem("addUserForm");
        return savedData ? JSON.parse(savedData) : {
            name: "",
            phoneNo: "",
            emailId: "",
            password: "",
            userRole: "",
        };
    });

    useEffect(() => {
        localStorage.setItem("addUserForm", JSON.stringify(formData));
        const token = localStorage.getItem("token");
        const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    
        if (!isLoggedIn || !token) {
            navigate("/"); // Redirect to login if not logged in
            return;
        }

    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, phoneNo, emailId, password, userRole } = formData;

        // Phone number validation for length
        if (phoneNo.length !== 10) {
            toast.error("Please enter a 10-digit phone number.");
            return;
        }

        if (!name || !phoneNo || !emailId || !password || !userRole) {
            toast.error("All fields are mandatory.");
            return;
        }

        try {
            const response = await axios.post("https://enquiry.igcsm.online/api/users/register", formData);
            if (response.status === 201) {
                toast.success("User added successfully!");
                localStorage.removeItem("addUserForm"); 
                setFormData({ 
                    name: "",
                    phoneNo: "",
                    emailId: "",
                    password: "",
                    userRole: "",
                });
                navigate("/users"); 
            }
        } catch (error) {
            console.error("Error adding user:", error);

            // Backend error message handling
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Failed to add user. Please try again.");
            }
        }
    };

    return (
      <>
        <ToastContainer />
        <div className="page">
          <div className="page-main">
            <Header />

            <div className="my-3 my-md-5">
              <div className="container">
                <div className="page-header">
                  <h1 className="page-title">Add User</h1>
                </div>

                <Paper style={{ padding: "20px" }}>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Phone No"
                          name="phoneNo"
                          value={formData.phoneNo}
                          onChange={(e) => {
                            // Allow only digits and limit to 10 digits
                            const value = e.target.value;
                            if (/^\d{0,10}$/.test(value)) {
                              // Regex to allow only 10 digits
                              setFormData({
                                ...formData,
                                phoneNo: value,
                              });
                            }
                          }}
                          required
                          inputProps={{
                            maxLength: 10, // Maximum length for the phone number
                          }}
                          inputMode="numeric" // Ensure numeric keypad on mobile devices
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Email ID"
                          name="emailId"
                          type="email"
                          value={formData.emailId}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Password"
                          name="password"
                          type="password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          select
                          fullWidth
                          label="Role"
                          name="userRole"
                          value={formData.userRole}
                          onChange={handleChange}
                          required
                        >
                          <MenuItem value="Admin">Admin</MenuItem>
                          <MenuItem value="User">User</MenuItem>
                          <MenuItem value="Staff">Staff</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "20px" }}
                    >
                      Add User
                    </Button>
                  </form>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AddUser;