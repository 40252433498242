import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admission from './pages/Admission';
import Login from './pages/Login';
import Users from './pages/Users';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import AddAdmission from './pages/AddAdmission';
import EditAdmission from './pages/EditAdmission';
import CollegeAdmission from './pages/CollegeAdmission';
import StudentFeedback from './pages/StudentFeedback';
import Enquiries from './pages/Enquiries';
import ViewAdmission from './pages/ViewAdmission';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admissions" element={<Admission />} />
        <Route path="/add-admissions" element={<AddAdmission />} />
        <Route path="/users" element={<Users />} />
        <Route path="/addusers" element={<AddUser />} />
        <Route path="/editusers/:id" element={<EditUser />} />
        <Route path="/viewadmission/:id" element={<ViewAdmission />} />
        <Route path="/editadmission/:id" element={<EditAdmission />} />
        <Route path="/collegeAdmission" element={<CollegeAdmission />} />
        <Route path="/studentFeedback" element={<StudentFeedback />} />
        <Route path="/Enquiries" element={<Enquiries />} />
      </Routes>
    </Router>
  );
}

export default App;