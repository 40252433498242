import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import { TextField, Button, Paper, Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditUser = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // URL se user ID ko fetch karte hain
    const [formData, setFormData] = useState({
        name: "",
        phoneNo: "",
        emailId: "",
        password: "",
        userRole: "",
    });

    useEffect(() => {
        const token = localStorage.getItem("token");
        const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    
        if (!isLoggedIn || !token) {
        navigate("/"); // Redirect to login if not logged in
        return;
        }

        // User details ko fetch karte hain
        const fetchUser = async () => {
            try {
                const response = await axios.get(`https://enquiry.igcsm.online/api/users/${id}`);
                const user = response.data;
                setFormData({
                    name: user.name || "",
                    phoneNo: user.phoneNo || "",
                    emailId: user.emailId || "",
                    password: user.password, 
                    userRole: user.userRole || "",
                });
            } catch (error) {
                console.error("Error fetching user data:", error);
                toast.error("Failed to fetch user details.");
            }
        };

        fetchUser();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, phoneNo, emailId, password, userRole } = formData;

        if (!name || !phoneNo || !emailId || !userRole) {
            toast.error("All fields except password are mandatory.");
            return;
        }

        try {
            const response = await axios.put(
                `https://enquiry.igcsm.online/api/users/${id}`,
                { name, phoneNo, emailId, password, userRole }
            );
            if (response.status === 200) {
                toast.success("User updated successfully!");
                navigate("/users"); // Redirect back to users list
            }
        } catch (error) {
            console.error("Error updating user:", error);
            toast.error("Failed to update user. Please try again.");
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="page">
                <div className="page-main">

                    <Header />

                    <div className="my-3 my-md-5">
                        <div className="container">
                            <div className="page-header">
                                <h1 className="page-title">Edit User</h1>
                            </div>

                            <Paper style={{ padding: "20px" }}>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="Phone No"
                                                name="phoneNo"
                                                value={formData.phoneNo}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="Email ID"
                                                name="emailId"
                                                type="email"
                                                value={formData.emailId}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                name="password"
                                                type="text" // Password will be visible as plain text
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                select
                                                fullWidth
                                                label="Role"
                                                name="userRole"
                                                value={formData.userRole}
                                                onChange={handleChange}
                                                required
                                            >
                                                <MenuItem value="Admin">Admin</MenuItem>
                                                <MenuItem value="User">User</MenuItem>
                                                <MenuItem value="Staff">Staff</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: "20px" }}
                                    >
                                        Update User
                                    </Button>
                                </form>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditUser;