import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Paper } from "@mui/material";
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from "material-react-table";
import axios from "axios";

const columnHelper = createMRTColumnHelper();

const CollegeAdmission = () => {
  const navigate = useNavigate();
  const [admissions, setAdmissions] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (!isLoggedIn || !token) {
      navigate("/"); // Redirect to login if not logged in
      return;
    }

    const fetchCollegeAdmission = async () => {
      try {
        const response = await axios.get("https://api.igcsm.online/api/admission", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdmissions(response.data);
      } catch (error) {
        console.error("Error fetching Admissions data:", error);
      }
    };

    fetchCollegeAdmission();
  }, [navigate]);

  const columns = [
    columnHelper.accessor("admissionNo", { header: "Admission No" }),
    columnHelper.accessor("admissionDate", { header: "Admission Date" }),
    columnHelper.accessor("branchName", { header: "Branch Name" }),
    columnHelper.accessor("semesterOrYear", { header: "Semester Or Year" }),
    columnHelper.accessor("studentName", { header: "Student Name" }),
    columnHelper.accessor("fathersName", { header: "Father's Name" }),
    columnHelper.accessor("dob", { header: "DOB" }),
    columnHelper.accessor("gender", { header: "Gender" }),
    columnHelper.accessor("mothersName", { header: "Mother's Name" }),
    columnHelper.accessor("category", { header: "Category" }),
    columnHelper.accessor("caste", { header: "Caste" }),
    columnHelper.accessor("subCategory", { header: "Sub Category" }),
    columnHelper.accessor("bloodGroup", { header: "Blood Group" }),
    columnHelper.accessor("jeecupRollNo", { header: "Jeecup Roll No" }),
    columnHelper.accessor("aadharCardNo", { header: "Aadhar Card No" }),
    columnHelper.accessor("drivingLicenseNo", { header: "Driving License No" }),
    columnHelper.accessor("antiRaggingRegNo", { header: "Anti Ragging RegNo" }),
    columnHelper.accessor("medicalFitnessCertNo", { header: "Medical Fitness CertNo" }),
    columnHelper.accessor("casteCertNo", { header: "CasteCert No" }),
    columnHelper.accessor("tcSerialNo", { header: "Tc SerialNo" }),
    columnHelper.accessor("appliedForHostel", { header: "Applied For Hostel" }),
    columnHelper.accessor("permanentAddress.block", { header: "Permanent Address Block" }),
    columnHelper.accessor("permanentAddress.district", { header: "Permanent Address District" }),
    columnHelper.accessor("permanentAddress.state", { header: "Permanent Address State" }),
    columnHelper.accessor("permanentAddress.pinCode", { header: "Permanent Address PinCode" }),
    columnHelper.accessor("mobileNo", { header: "Mobile No" }),
    columnHelper.accessor("email", { header: "Email" }),
    columnHelper.accessor("jeecupFeeDetails.transactionNo", { header: "Jeecup FeeDetails TransactionNo" }),
    columnHelper.accessor("jeecupFeeDetails.date", { header: "Jeecup FeeDetails Date" }),
    columnHelper.accessor("educationalDetails.highSchool.boardOrUniversity", {
      header: "EducationalDetails HighSchool BoardOrUniversity",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.highSchool.passingYear", {
      header: "EducationalDetails HighSchool PassingYear",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.highSchool.rollNo", {
      header: "EducationalDetails HighSchool RollNo",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.highSchool.maxMarks", {
      header: "EducationalDetails HighSchool MaxMarks",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.highSchool.percentMarks", {
      header: "EducationalDetails HighSchool PercentMarks",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.graduation.boardOrUniversity", {
      header: "EducationalDetails Graduation BoardOrUniversity",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.graduation.passingYear", {
      header: "EducationalDetails Graduation PassingYear",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.graduation.rollNo", {
      header: "EducationalDetails Graduation RollNo",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.graduation.maxMarks", {
      header: "EducationalDetails Graduation MaxMarks",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("educationalDetails.graduation.percentMarks", {
      header: "EducationalDetails Graduation PercentMarks",
      headerProps: {
        sx: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
      },
    }),
    columnHelper.accessor("collegeName", { header: "College Name" }),
  ];

  const table = useMaterialReactTable({
    columns,
    data: admissions,
    initialState: {
      density: "compact", // Set default density to "compact"
    },
  });

  return (
    <div className="page">
      <div className="page-main">
        <Header />
        <div className="my-3 my-md-5">
          <div className="container">
            <div className="page-header">
              <h1 className="page-title">College Admissions</h1>
            </div>
            <Paper>
              <MaterialReactTable table={table} />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeAdmission;