import React, { useState, useEffect, useMemo } from "react";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axiosInstance from "../instance/instance";
import validator from "aadhaar-validator";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Controller } from "react-hook-form";

const AddAdmission = () => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  const navigate = useNavigate(); // Initialize navigate function

  const watchedValues = watch();
  useEffect(() => {
    const {
      tenthMarksheet,
      twelfthMarksheet,
      graduationMarksheet,
      profilePhoto,
      ...otherValues
    } = watchedValues;
    localStorage.setItem("values", JSON.stringify(otherValues));
  }, [watchedValues]);

  const formValues = useMemo(() => {
    const storedValues = JSON.parse(localStorage.getItem("values"));
    return storedValues;
  }, []);

  useEffect(() => {
    if (formValues) {
      reset(formValues);
    }

    const token = localStorage.getItem("token");
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  
    if (!isLoggedIn || !token) {
      navigate("/"); // Redirect to login if not logged in
      return;
    }
  }, [reset, formValues]);


  const validateFile = (file, allowedExtensions) => {
    const fileName = file?.name || "";
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const onSubmit = async (values) => {
    // Validate Phone Number
    if (!/^\d{10}$/.test(values.phoneNumber)) {
      toast.error("Please enter a 10 digit mobile number");
      return;
    }

    const isValidAadhar = validator.isValidNumber(values.aadharCard);
    if (!isValidAadhar) {
      toast.error("Invalid Aadhaar number");
      return;
    }

    // Validate Files
    const profilePhotoFile = values.profilePhoto?.[0];
    if (profilePhotoFile && !validateFile(profilePhotoFile, ["jpeg", "jpg", "png"])) {
      toast.error("Profile photo must be JPG or PNG format.");
      return;
    }

    const filesToValidate = [
      { file: values.tenthMarksheet?.[0], name: "10th marksheet" },
      { file: values.twelfthMarksheet?.[0], name: "12th marksheet" },
      { file: values.graduationMarksheet?.[0], name: "graduation marksheet" },
    ];

    for (const { file, name } of filesToValidate) {
      if (file && !validateFile(file, ["pdf"])) {
        toast.error(`${name} must be a PDF file.`);
        return;
      }
    }

    localStorage.setItem("values", JSON.stringify(values));
    if (values) {
      const formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        if (
          key === "tenthMarksheet" ||
          key === "twelfthMarksheet" ||
          key === "graduationMarksheet" ||
          key === "profilePhoto"
        ) {
          formData.append(key, value[0]);
        } else {
          formData.append(key, value);
        }
      });

      if (formData) {
        try {
          const res = await axiosInstance.post(
            "/api/admission/register",
            formData
          );
          console.log(res, "reso");

          if (res.status === 201) {
            toast.success("Form submitted successfully!");
            localStorage.removeItem("values");
            reset({
              branchName: "",
              semesterOrYear: "",
              studentName: "",
              fathersName: "",
              mothersName: "",
              dob: "",
              gender: "",
              category: "",
              caste: "",
              bloodGroup: "",
              phoneNumber: "",
              email: "",
              aadharCard: "",
              address: "",
              highSchoolBoard: "",
              highSchoolYear: "",
              highSchoolMaxMarks: "",
              highSchoolPercentage: "",
              graduationBoard: "",
              graduationYear: "",
              graduationMaxMarks: "",
              graduationPercentage: "",
            }); // Reset all fields

            navigate("/admissions"); // Redirect to /admissions page
          }
        } catch (error) {
          console.error("Error adding admission:", error);

          // Backend error message handling
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Admission Failed. Please try again.");
          }
        }
      }
    }
  };

  const file = watch("profilePhoto");
  return (
    <>
      <ToastContainer />
      <div className="page">
        <div className="page-main">
          <Header />

          <div className="my-3 my-md-5">
            <div className="container">
              <div className="page-header">
                <h1 className="page-title">Add Admission</h1>
              </div>

              <Paper style={{ padding: "20px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        fullWidth
                        label="Name of Branch"
                        {...register("branchName")}
                        required
                      />
                      <TextField
                        fullWidth
                        label="Semester / Year"
                        required
                        {...register("semesterOrYear")}
                        style={{ marginTop: "10px" }}
                      />
                      <TextField
                        fullWidth
                        label="Name of Student"
                        required
                        {...register("studentName")}
                        style={{ marginTop: "10px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="Selected"
                          src={
                            file && file.length > 0
                              ? URL.createObjectURL(file[0])
                              : "https://cdn3d.iconscout.com/3d/premium/thumb/graduate-student-avatar-3d-icon-download-in-png-blend-fbx-gltf-file-formats--study-school-graduation-profession-pack-people-icons-8179543.png?f=webp"
                          }
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            height: "174px",
                            marginBottom: "10px",
                          }}
                        />
                        <Button variant="contained" component="label">
                          Upload Photo
                          <input
                            type="file"
                            hidden
                            {...register("profilePhoto")}
                            accept=".jpeg,.jpg,.png"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Father's Name"
                        required
                        {...register("fathersName")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Mother's Name"
                        {...register("mothersName")}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Date of Birth"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        {...register("dob")}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabel>Gender</FormLabel>
                      <Controller
                        name="gender"
                        control={control} // Pass control here
                        defaultValue=""
                        render={({ field }) => (
                          <RadioGroup row {...field}>
                            <FormControlLabel
                              value="Male"
                              control={<Radio />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Category"
                        required
                        {...register("category")}
                      >
                        {[
                          "GEN",
                          "GEN(EWS)",
                          "GEN MINORITY",
                          "OBC MINORITY",
                          "OBC",
                          "SC",
                          "ST",
                        ].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Caste"
                        required
                        {...register("caste")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Blood Group"
                        required
                        {...register("bloodGroup")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        type="text" // Keep type as text
                        required
                        {...register("phoneNumber")}
                        inputMode="numeric" // Numeric keypad on mobile devices
                        inputProps={{
                          maxLength: 10, // Restrict input length to 10 characters
                        }}
                        onInput={(e) => {
                          // Restricting input to only digits
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        type="email"
                        required
                        {...register("email")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Aadhar Card No"
                        type="text"
                        required
                        {...register("aadharCard")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Permanent Address"
                        required
                        multiline
                        {...register("address")}
                        rows={3}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="High School (Board / University)"
                        required
                        {...register("highSchoolBoard")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="High School (Passing Year)"
                        type="number"
                        required
                        {...register("highSchoolYear")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="High School (Max Marks)"
                        type="number"
                        required
                        {...register("highSchoolMaxMarks")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="High School (% Marks)"
                        type="number"
                        required
                        {...register("highSchoolPercentage")}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Graduation (Board / University)"
                        required
                        {...register("graduationBoard")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Graduation (Passing Year)"
                        type="number"
                        required
                        {...register("graduationYear")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Graduation (Max Marks)"
                        type="number"
                        required
                        {...register("graduationMaxMarks")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Graduation (% Marks)"
                        type="number"
                        required
                        {...register("graduationPercentage")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={4}>
                      <div>
                        <label className="form-label" htmlFor="tenthMarksheet">
                          Upload 10th Class Marksheet
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="tenthMarksheet"
                          {...register("tenthMarksheet")}
                          accept=".pdf"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div>
                        <label
                          className="form-label"
                          htmlFor="twelfthMarksheet"
                        >
                          Upload 12th Class Marksheet
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="twelfthMarksheet"
                          {...register("twelfthMarksheet")}
                          accept=".pdf"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div>
                        <label
                          className="form-label"
                          htmlFor="graduationMarksheet"
                        >
                          Upload Graduation Marksheet
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="graduationMarksheet"
                          {...register("graduationMarksheet")}
                          accept=".pdf"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Admission For"
                        required
                        {...register("admissionFor")}
                      >
                        {[
                          "ITI KANTH",
                          "ITI KHAIR",
                          "ITI BALDEV",
                          "POLYTECHNIC BILSI",
                          "POLYTECHNIC SANDILA",
                          "POLYTECHNIC GOPAMAU",
                          "POLYTECHNIC SIKANDRA RAO",
                          "POLYTECHNIC FARIDPUR",
                        ].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "50px" }}
                    // disabled={isSubmitting}
                  >
                    {/* { ? "Submitting..." : "Submit Admission"} */}
                    submit
                  </Button>
                </form>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmission;
