import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Paper } from "@mui/material";
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from "material-react-table";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create column helper for MRT
const columnHelper = createMRTColumnHelper();

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (!isLoggedIn || !token) {
      navigate("/"); // Redirect to login if not logged in
      return;
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get("https://enquiry.igcsm.online/api/users");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };

    fetchUsers();
  }, [navigate]);

  const deleteUser = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://enquiry.igcsm.online/api/users/${userId}`);
      setUsers(users.filter((user) => user._id !== userId)); // Remove deleted user from the state
      toast.success("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user. Please try again.");
    }
  };

  // Define columns for the table
  const columns = [
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("phoneNo", { header: "Phone" }),
    columnHelper.accessor("emailId", { header: "Email" }),
    columnHelper.accessor("userRole", { header: "Role" }),
    {
      id: "actions", // Custom column for Actions
      header: "Actions",
      Cell: ({ row }) => (
        <div>
          <button
            onClick={() => navigate(`/editusers/${row.original._id}`)}
            className="btn btn-sm btn-outline-primary"
            style={{ marginRight: "8px" }}
          >
            Edit
          </button>
          <button
            onClick={() => deleteUser(row.original._id)}
            className="btn btn-sm btn-outline-danger"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: users,
    initialState: {
      density: "compact", // Set default density to "compact"
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="page">
        <div className="page-main">
          <Header />
          <div className="my-3 my-md-5">
            <div className="container">
              <div className="page-header">
                <h1 className="page-title">Users</h1>
              </div>

              <Paper>
                <Link
                  to="/addusers"
                  className="btn btn-sm btn-outline-primary"
                  style={{ margin: "10px" }}
                >
                  Add Users
                </Link>
                <MaterialReactTable table={table} />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;