import React, { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axiosInstance from "../instance/instance"; 
import validator from "aadhaar-validator";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Controller } from "react-hook-form";

const ViewAdmission = () => {
  const { id } = useParams();
  const { register, handleSubmit, reset, watch, setValue, setError, control } = useForm();
  const navigate = useNavigate(); // Initialize navigate
  const watchedValues = watch();

  console.log("graduationMaxMarks:", watchedValues.graduationMaxMarks);
  console.log("graduationPercentage:", watchedValues.graduationPercentage); 

  // Function to handle file previews
  const filePreview = (file) => {
    if (typeof file === "string" && file) {
      const url = `https://enquiry.igcsm.online/${file.replace(/\\/g, "/")}`;
      return { type: file.endsWith(".pdf") ? "pdf" : "image", url };
    } else if (file instanceof FileList || file instanceof File) {
      const fileURL = URL.createObjectURL(file[0] || file);
      const fileType = file[0]?.type || file.type;
      return {
        type: fileType.includes("pdf") ? "pdf" : "image",
        url: fileURL,
      };
    }
    return { type: "image", url: "https://via.placeholder.com/150" };
  };

  // Fetch Admission Details
  useEffect(() => {
    const token = localStorage.getItem("token");
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  
    if (!isLoggedIn || !token) {
      navigate("/"); // Redirect to login if not logged in
      return;
    }

    const fetchAdmission = async () => {
      try {
        const response = await axiosInstance.get(`/api/admission/${id}`);
        const admission = response.data;

        console.log("Fetched Admission Data:", admission); // Log to check if graduationMaxMarks and graduationPercentage are available

         // Set existing files to the form
        setValue("profilePhoto", admission.profilePhoto || "");
        setValue("tenthMarksheet", admission.tenthMarksheet || "");
        setValue("twelfthMarksheet", admission.twelfthMarksheet || "");
        setValue("graduationMarksheet", admission.graduationMarksheet || "");

        reset({
          branchName: admission.branchName || "",
          semesterOrYear: admission.semesterOrYear || "",
          studentName: admission.studentName || "",
          fathersName: admission.fathersName || "",
          mothersName: admission.mothersName || "",
          dob: admission.dob || "",
          gender: admission.gender || "",
          category: admission.category || "",
          caste: admission.caste || "",
          bloodGroup: admission.bloodGroup || "",
          phoneNumber: admission.phoneNumber || "",
          email: admission.email || "",
          aadharCard: admission.aadharCard || "",
          address: admission.address || "",
          highSchoolBoard: admission.highSchoolBoard !== undefined ? admission.highSchoolBoard : "",
          highSchoolYear: admission.highSchoolYear !== undefined ? admission.highSchoolYear : "",
          highSchoolMaxMarks: admission.highSchoolMaxMarks !== undefined ? admission.highSchoolMaxMarks : "",
          highSchoolPercentage: admission.highSchoolPercentage !== undefined ? admission.highSchoolPercentage : "",
          graduationBoard: admission.graduationBoard !== undefined ? admission.graduationBoard : "",
          graduationYear: admission.graduationYear !== undefined ? admission.graduationYear : "",
          graduationMaxMarks: admission.graduationMaxMarks !== undefined ? admission.graduationMaxMarks : "",
          graduationPercentage: admission.graduationPercentage !== undefined ? admission.graduationPercentage : "",
          admissionFor: admission.admissionFor || "",
        });

        setValue("profilePhoto", admission.profilePhoto || null);
        setValue("tenthMarksheet", admission.tenthMarksheet || null);
        setValue("twelfthMarksheet", admission.twelfthMarksheet || null);
        setValue("graduationMarksheet", admission.graduationMarksheet || null);
      } catch (error) {
        console.error("Error fetching admission data:", error);
        toast.error("Failed to fetch admission details.");
      }
    };

    if (id) {
      fetchAdmission();
    }
  }, [id, reset, setValue]);

  const validatePhoneNumber = (value) => {
    if (!/^\d{10}$/.test(value)) {
      return "Please enter a 10-digit number";
    }
    return true;
  };

  const validateFileFormat = (file, allowedFormats) => {
    if (file && !allowedFormats.includes(file.type)) {
      return false;
    }
    return true;
  };

  const validateExistingFileFormat = (fileUrl, allowedFormats) => {
    const fileExtension = fileUrl?.split(".").pop()?.toLowerCase();
    return allowedFormats.includes(fileExtension);
  };

  // Submit Form
  const onSubmit = async (values) => {
    const profilePhoto = values.profilePhoto?.[0];
    const tenthMarksheet = values.tenthMarksheet?.[0];
    const twelfthMarksheet = values.twelfthMarksheet?.[0];
    const graduationMarksheet = values.graduationMarksheet?.[0];

    // Validate newly uploaded files
    if (
      profilePhoto &&
      typeof profilePhoto !== "string" &&
      !validateFileFormat(profilePhoto, ["image/jpeg", "image/jpg", "image/png"])
    ) {
      toast.error("Profile photo must be JPG or PNG format.");
      return;
    }

    if (
      !profilePhoto &&
      values.profilePhoto &&
      !validateExistingFileFormat(values.profilePhoto, ["jpeg", "jpg", "png"])
    ) {
      toast.error("Existing profile photo must be in JPG, JPEG, or PNG format.");
      return;
    }

    const validateMarksheets = (marksheet, existingFileUrl, label) => {
      if (marksheet && typeof marksheet !== "string") {
        if (!validateFileFormat(marksheet, ["application/pdf"])) {
          toast.error(`Please upload ${label} in PDF format.`);
          return false;
        }
      } else if (!marksheet && existingFileUrl) {
        if (!validateExistingFileFormat(existingFileUrl, ["pdf"])) {
          toast.error(`Existing ${label} must be in PDF format.`);
          return false;
        }
      }
      return true;
    };

    if (!validateMarksheets(tenthMarksheet, values.tenthMarksheet, "10th Marksheet")) return;
    if (!validateMarksheets(twelfthMarksheet, values.twelfthMarksheet, "12th Marksheet")) return;
    if (!validateMarksheets(graduationMarksheet, values.graduationMarksheet, "Graduation Marksheet")) return;

    if (!/^\d{10}$/.test(values.phoneNumber)) {
      setError("phoneNumber", {
        type: "manual",
        message: "Please enter a 10-digit number",
      });
      toast.error("Please enter a 10-digit number");
      return;
    }


    const isValidAadhar = validator.isValidNumber(values.aadharCard);
    if (!isValidAadhar) {
      toast.error("Invalid Aadhaar number");
      return;
    }

    // Proceed with form submission if all validations pass
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (
        key === "tenthMarksheet" ||
        key === "twelfthMarksheet" ||
        key === "graduationMarksheet" ||
        key === "profilePhoto"
      ) {
        if (value instanceof FileList || value instanceof File) {
          formData.append(key, value[0] || value);
        }
      } else {
        formData.append(key, value);
      }
    });
  };
  

  return (
    <>
      <ToastContainer />
      <div className="page">
        <div className="page-main">
          <Header />

          <div className="my-3 my-md-5">
            <div className="container">
              <div className="page-header">
                <h1 className="page-title">View Admission</h1>
              </div>

              <Paper style={{ padding: "20px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        fullWidth
                        label="Name of Branch"
                        {...register("branchName")}
                        required
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        fullWidth
                        required
                        label="Semester / Year"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("semesterOrYear")}
                        style={{ marginTop: "10px" }}
                      />
                      <TextField
                        fullWidth
                        required
                        label="Name of Student"
                        {...register("studentName")}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "10px" }}
                      />
                    </Grid>
                    {/* Profile Photo */}
                    <Grid item xs={12} sm={4}>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {/* Display the Profile Photo */}
                        <img
                          alt="Profile"
                          src={
                            filePreview(watchedValues.profilePhoto)?.url ||
                            "https://via.placeholder.com/150"
                          }
                          style={{
                            width: "100%",
                            maxWidth: "210px",
                            height: "175px",
                            marginBottom: "10px",
                            borderRadius: "10px",
                          }}
                        />
                        {/* Upload Button */}
                        {/* <Button
                          variant="contained"
                          component="label"
                          style={{ marginTop: "10px" }}
                        >
                          Upload Photo
                          <input
                            type="file"
                            hidden
                            {...register("profilePhoto")}
                            accept=".jpeg,.jpg,.png"
                          />
                        </Button> */}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Father's Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("fathersName")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Mother's Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("mothersName")}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="date"
                        label="Date of Birth"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("dob")}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabel>Gender</FormLabel>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue="" // Default value
                        render={({ field }) => (
                          <RadioGroup row {...field} InputProps={{ readOnly: true }}>
                            <FormControlLabel
                              value="Male"
                              control={<Radio />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        required
                        label="Category"
                        value={watch("category") || ""}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("category")}
                      >
                        {[
                          "GEN",
                          "GEN(EWS)",
                          "GEN MINORITY",
                          "OBC MINORITY",
                          "OBC",
                          "SC",
                          "ST",
                        ].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Caste"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("caste")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Blood Group"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("bloodGroup")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="tel"
                        label="Phone Number"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        required
                        {...register("phoneNumber", {
                          required: true,
                          validate: validatePhoneNumber,
                        })}
                        error={
                          !!watchedValues.phoneNumber &&
                          validatePhoneNumber(watchedValues.phoneNumber) !==
                            true
                        }
                        helperText={
                          watchedValues.phoneNumber &&
                          validatePhoneNumber(watchedValues.phoneNumber) !==
                            true
                            ? validatePhoneNumber(watchedValues.phoneNumber)
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="email"
                        label="Email Address"
                        required
                        {...register("email")}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="text"
                        required
                        label="Aadhar Card No"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("aadharCard")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        multiline
                        label="Permanent Address"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("address")}
                        rows={3}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    {/* High School Board */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="High School (Board / University)"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("highSchoolBoard")}
                      />
                    </Grid>

                    {/* High School Passing Year */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="highSchoolYear"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            required
                            label="High School (Passing Year)"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={field.value === 0 ? 0 : field.value || ""}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value) || 0)
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* High School Max Marks */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="highSchoolMaxMarks"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            required
                            label="High School (Max Marks)"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={field.value === 0 ? 0 : field.value || ""}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value) || 0)
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* High School Percentage */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="highSchoolPercentage"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            required
                            label="High School (% Marks)"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={field.value === 0 ? 0 : field.value || ""}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value) || 0)
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Graduation Board */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Graduation (Board / University)"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        {...register("graduationBoard")}
                      />
                    </Grid>

                    {/* Graduation Passing Year */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="graduationYear"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            required
                            label="Graduation (Passing Year)"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={field.value === 0 ? 0 : field.value || ""}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value) || 0)
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Graduation Max Marks */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="graduationMaxMarks"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            required
                            label="Graduation (Max Marks)"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={field.value === 0 ? 0 : field.value || ""}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value) || 0)
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Graduation Percentage */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="graduationPercentage"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            required
                            label="Graduation (% Marks)"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            value={field.value === 0 ? 0 : field.value || ""}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value) || 0)
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    {/* 10th Marksheet Upload */}
                    <Grid item xs={12} sm={4}>
                      <div>
                        <label className="form-label" htmlFor="tenthMarksheet">
                          View 10th Class Marksheet
                        </label>
                        <div style={{ marginTop: "10px" }}>
                          {watchedValues.tenthMarksheet &&
                            (() => {
                              const { type, url } = filePreview(
                                watchedValues.tenthMarksheet
                              );
                              return type === "pdf" ? (
                                <iframe
                                  src={url}
                                  title="10th Marksheet Preview"
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "200px",
                                    border: "1px solid #ccc",
                                  }}
                                ></iframe>
                              ) : (
                                <img
                                  src={url}
                                  alt="Preview 10th Marksheet"
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              );
                            })()}
                        </div>
                      </div>
                    </Grid>

                    {/* 12th Marksheet Upload */}
                    <Grid item xs={12} sm={4}>
                      <div>
                        <label
                          className="form-label"
                          htmlFor="twelfthMarksheet"
                        >
                          View 12th Class Marksheet
                        </label>
                        <div style={{ marginTop: "10px" }}>
                          {watchedValues.twelfthMarksheet &&
                            (() => {
                              const { type, url } = filePreview(
                                watchedValues.twelfthMarksheet
                              );
                              return type === "pdf" ? (
                                <iframe
                                  src={url}
                                  title="12th Marksheet Preview"
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "200px",
                                    border: "1px solid #ccc",
                                  }}
                                ></iframe>
                              ) : (
                                <img
                                  src={url}
                                  alt="Preview 12th Marksheet"
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              );
                            })()}
                        </div>
                      </div>
                    </Grid>

                    {/* Graduation Marksheet Upload */}
                    <Grid item xs={12} sm={4}>
                      <div>
                        <label
                          className="form-label"
                          htmlFor="graduationMarksheet"
                        >
                          View Graduation Marksheet
                        </label>
                        <div style={{ marginTop: "10px" }}>
                          {watchedValues.graduationMarksheet &&
                            (() => {
                              const { type, url } = filePreview(
                                watchedValues.graduationMarksheet
                              );
                              return type === "pdf" ? (
                                <iframe
                                  src={url}
                                  title="Graduation Marksheet Preview"
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "200px",
                                    border: "1px solid #ccc",
                                  }}
                                ></iframe>
                              ) : (
                                <img
                                  src={url}
                                  alt="Preview Graduation Marksheet"
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              );
                            })()}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                <Link to={"/admissions"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "50px" }}
                  >
                    Back
                  </Button>
                </Link>
                </form>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAdmission;